<template>
  <div id="Login" class="login fullheight" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <CContainer fluid class="fullheight"> 
      <CRow class="fullheight" style="width: 100%; margin: auto;">
        <CCol cols="12" md="12" style="width: 100%; margin-top: 80px !important;">
          <div class="login__container" style="margin: auto; background-color: #F6F6F6 !important;">
            <div class="login__inner">
              <div class="login__image">
                <img src="main_logo.jpg" style="width: 300px; margin: 0 0px;" alt="Logo Oscuro">
              </div>
              <div class="login__header pt-5">
                  <h4>Inicia Sesión</h4>
              </div>
              <div class="login__subscribe d-flex justify-space-between align-center pb-8">
              </div>
              <div class="login__form">
                <CRow>
                  <CCol sm="12">
                    <CInput
                      v-model="email"
                      label="Correo Electrónico"  
                      placeholder=""
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <CInput
                      v-model="password"
                      label="Contraseña"
                      type="password"
                      placeholder=""
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <CButton type="button" color="primary" style="width: 100%" @click="login">INICIAR SESIÓN</CButton>
                  </CCol>
                </CRow>
              </div>
              <div class="login__actions d-flex justify-space-between py-2">
                  <div class="login__remind" style="font-size: 12px;">
                  </div>
                  <div class="login__recovery-password" style="width: 100%">
                      <!-- <a href="/#/forget" class="font-weight-regular px-1" style="font-size: 12px; float: right !important;">¿Olvidaste tu contraseña?</a> -->
                  </div>
              </div>
            </div>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
import router from '../../../router/index'
import login from '../../../services/login';
import ws from '../../../services/general';
import store from '../../../store'
import axios from 'axios'

export default {
  name: 'Login',
  data: function () {
      return {
          email: '',
          password: '',
          image: ''
      }
  },
  mounted: async function() {
    let response = await ws.getParameters(); 

    if(response.type == "success"){
      let parameters = response.data;

      let param1 = this.$findElementByName(parameters, "LOGIN_IMAGE");
      this.image = 'login.jpg';
    }
  },
  methods: {
    login: async function(){
      let params = {
        email: this.email,
        password: this.password,
        device_name: navigator.userAgent
      };

      let response = await login.login(params);

      if(response.type == "success"){
        let data = response.data;

        localStorage.setItem("domain", "demo");
        localStorage.setItem("token", data.api_token);
        localStorage.setItem("role", data.role ? data.role : data.roles[0]);

        axios.defaults.headers.common['domain'] = "demo";
        axios.defaults.headers.common['api-token'] = data.api_token;
        
        if(data.role ? data.role : data.roles[0].slug == "administrator"){
          window.location.href = "/#/home"; return;
        }
        else{
          if(data.role ? data.role : data.roles[0].slug == "supplier"){
            window.location.href = "/#/welcome"; return;
          }
          else{
            if(data.role ? data.role : data.roles[0].slug == "manager"){
              window.location.href = "/#/welcome"; return;
            }
            else{
              window.location.href = "/#/404"; return;
            } 
          } 
        }
      }
      else{
        alert(response.message);
      }
    },
  }
}
</script>
